import { Component, Prop } from 'vue-property-decorator';
import { VueComponent } from '~/utils/vue-component';

import { Headline, Image } from '~/components/atoms';
import { Align } from '~/components/atoms/headline/Headline';
import { CztWidgets } from '~/utils/views/widgets';

import style from './SmartGuide.scss';
import { SmartGuideInterface } from './types';
import { Link } from '~/components/molecules';
import { Target } from '~/utils/molecules/link';

import logo from '~/assets/smart_guide-logo.svg';

const rootClass: string = 'czt-smart-guide';

@Component({
  style,
})
export default class SmartGuide extends VueComponent<SmartGuideInterface>
  implements SmartGuideInterface {
  @Prop()
  public anchorId?: string;

  @Prop()
  public audioUrl?: string;

  @Prop()
  public deepLinkUrl?: string;

  @Prop({ default: false })
  public isBottomSpacingCollapsed!: boolean;

  @Prop({ default: false })
  public isTopSpacingCollapsed!: boolean;

  @Prop({ default: false, type: Boolean })
  public side!: boolean;

  @Prop({ required: true, type: String })
  public title!: string;

  public className = CztWidgets.HTML;

  protected audioLoading: boolean = true;

  protected audioLength: number = 0;

  protected currentAudioTime: number = 0;

  protected playing: boolean = false;

  protected get buttonIcon(): string {
    return !this.playing
      ? '$vuetify.icons.common-Play'
      : '$vuetify.icons.common-Pause';
  }

  protected audio: HTMLAudioElement | null = null;

  public mounted() {
    if (this.audioUrl) {
      this.audio = new Audio(this.audioUrl);
      this.audio.addEventListener('loadedmetadata', this.onLoadedMetaData);
      this.audio.addEventListener('playing', this.onPlaying);
      this.audio.addEventListener('pause', this.onPause);
      this.audio.addEventListener('timeupdate', this.onTimeUpdate);
      this.audio.addEventListener('ended', this.onEnded);
    }
  }

  public render() {
    const rootClasses: string[] = [rootClass, 'czt-spacer'];
    if (this.isBottomSpacingCollapsed) {
      rootClasses.push('czt-spacer--collapse-bottom');
    }
    if (this.isTopSpacingCollapsed) {
      rootClasses.push('czt-spacer--collapse-top');
    }

    return (
      <div id={this.anchorId} class={rootClasses.join(' ')}>
        <v-container>
          <div class={`${rootClass}__headline`}>
            <Headline level={this.side ? 4 : 2} underscore align={Align.LEFT}>
              {this.title}
            </Headline>
          </div>
          <div style={{ maxWidth: '466px' }}>
            {!!this.audioUrl && (
              <div
                class={`${rootClass}__wrapper`}
                style={{
                  position: 'relative',
                  height: 0,
                  width: '100%',
                  paddingTop: 'calc(100% / 45 * 11)',
                }}
              >
                <div
                  class={`${rootClass}__player`}
                  style={{
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    background:
                      'linear-gradient(90deg, #17b3aa 0%, #37bf21 100%)',
                    borderRadius: '100px',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    padding: '0 8% 0 4%',
                  }}
                >
                  <div
                    style={{
                      position: 'relative',
                      height: 0,
                      width: 'calc(100% / 45 * 11 - 4.5%)',
                      paddingTop: 'calc(100% / 45 * 11 - 4.5%)',
                      marginRight: '4%',
                    }}
                  >
                    <v-btn
                      absolute
                      fab
                      v-blur
                      small
                      x-large
                      elevation={0}
                      color='white'
                      loading={this.audioLoading}
                      onClick={(e: Event) => {
                        if (this.audio) {
                          if (this.audio.paused) {
                            this.audio.play();
                          } else {
                            this.audio.pause();
                          }
                        }
                      }}
                      style={{
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                      }}
                    >
                      <v-icon
                        size='25%'
                        color='#34bf05'
                        style={{ display: 'block' }}
                      >
                        {this.buttonIcon}
                      </v-icon>
                    </v-btn>
                  </div>
                  {!!this.audioLength && (
                    <v-slider
                      readonly
                      value={this.currentAudioTime}
                      min='0'
                      max={this.audioLength}
                      color='#3c3c3c'
                      track-color='white'
                    />
                  )}
                </div>
              </div>
            )}
            {this.deepLinkUrl && (
              <Link url={this.deepLinkUrl} target={Target.BLANK}>
                <Image
                  class={`${rootClass}__logo`}
                  src={logo}
                  alt='SmartGuide Logo'
                  style={{
                    maxWidth: '46.25%',
                    margin: '1.5rem auto 0',
                  }}
                />
              </Link>
            )}
          </div>
        </v-container>
      </div>
    );
  }

  public beforeDestroy() {
    if (this.audio) {
      this.audio.pause();
      this.audio.removeEventListener('loadedmetadata', this.onLoadedMetaData);
      this.audio.removeEventListener('playing', this.onPlaying);
      this.audio.removeEventListener('pause', this.onPause);
      this.audio.removeEventListener('timeupdate', this.onTimeUpdate);
      this.audio.removeEventListener('ended', this.onEnded);
      this.audio = null;
    }
  }

  protected onLoadedMetaData() {
    this.audioLength = this.audio?.duration || 0;
    this.audioLoading = false;
  }

  protected onPlaying() {
    this.playing = true;
  }

  protected onPause() {
    this.playing = false;
  }

  protected onTimeUpdate() {
    this.currentAudioTime = this.audio?.currentTime || 0;
  }

  protected onEnded() {
    this.playing = false;
  }
}
